import React, { useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  useLayoutDispatch,
  toggleSidebar,
} from "../../../../context/LayoutContext";
import {
  Inbox as InboxIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  openbyDefault,
}) {
  var classes = useStyles();
  const layoutDispatch = useLayoutDispatch();

  // local
  var [isOpen, setIsOpen] = useState(!!openbyDefault);
  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  if (!children)
    return (
      <Tooltip title={isSidebarOpened ? "" : label} placement="right">
        <ListItem
          component={link && Link}
          to={link}
          // classes={classes.link}
          sx={{ marginTop: "10px" }}
          classes={{
            root: classnames(classes.link, {
              [classes.linkActive]: isLinkActive,
              [classes.linkNested]: nested,
            }),
          }}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
            sx={{ color: isLinkActive ? "#00FFEB" : "#e3e3e3" }}
          >
            {icon}
            {/* {nested ? <Dot color={isLinkActive && "primary"} /> : icon} */}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            sx={{ color: isLinkActive ? "#FFF" : "#e3e3e3" }}
            primary={label}
          />
        </ListItem>
      </Tooltip>
    );

  return (
    <>
      <Tooltip title={isSidebarOpened ? "" : label} placement="right">
        <ListItem
          button
          component={link && Link}
          onClick={(e) => {
            if (!isSidebarOpened) {
              toggleSidebar(layoutDispatch);
            }
            toggleCollapse(e);
          }}
          className={classes.link}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
            sx={{ color: isLinkActive ? "#00FFEB" : "#e3e3e3" }}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            sx={{ color: isLinkActive ? "#FFFFFF" : "#777" }}
            primary={label}
          />
          {isSidebarOpened ? (
            <div>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
          ) : null}
        </ListItem>
      </Tooltip>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" sx={{ padding: "8px" }}>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
